@import url('https://fonts.googleapis.com/css?family=Roboto+Slab:400,700');

@font-face {
  font-family: 'MYRIADPRO-REGULAR';
  src: url('../fonts/MYRIADPRO-REGULAR.woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FranklinGothicHeavyC';
  src: url('../fonts/FranklinGothicHeavyC.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MinionPro-Bold';
  src: url('../fonts/MinionPro-Bold.otf'), url('../fonts/MinionPro-Semibold.otf') format('otf');
  font-weight: normal;
  font-style: normal;
}

body,
div,
dl,
dt,
dd,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset,
img,
abbr {
  border: 0;
}

address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var {
  font-style: normal;
  font-weight: normal;
}

ul li {
  list-style: none;
}

caption,
th {
  text-align: left;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

input,
textarea,
select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}

legend {
  color: #000;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
main {
  display: block;
}

img {
  max-width: 100%;
  height: auto;
}

.wrapper {
  max-width: 1270px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
}

body {
  font-family: 'MYRIADPRO-REGULAR', serif;
  font-size: 14px;
  line-height: 18px;
  color: #333;
  background: #000;
}

.overlay_content_from_menu {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.1);
  display: none;
  z-index: 1000;
}

.open {
  display: block;
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
}

#content1 {
  height: auto !important;
}

.info_header .containers {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  background: #55221f url(../img/header.png) no-repeat left top;
  height: 245px;
  padding-top: 15px;
  justify-content: flex-end;
}

.info_header .containers .block {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(66.66666666666667% - 30px);
  display: flex;
  justify-content: space-between;
}

.info_header .containers .block .logo {
  padding-top: 130px;
}

.info_header .containers .block .info p {
  font-size: 12px;
  color: #fff;
  font-weight: bold;
  line-height: 24px;
  padding-bottom: 15px;
  text-align: right;
}

.info_header .containers .block .info h2 {
  color: #f39100;
  font-size: 25px;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 34px;
  text-align: right;
}

.info_header .containers .block .info h3 {
  color: #b1b2b3;
  font-size: 20px;
  line-height: 34px;
  text-align: right;
}

.info_header .containers .block .info img {
  padding-top: 15px;
}

.info_header .extra_menu {
  position: relative;
  z-index: 1001;
}

.info_header .extra_menu .container {
  background: #55221f url(../img/wrap_bg.png) repeat-y 7px 0;
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.info_header .extra_menu .container .navigation {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(100% - 30px);
  display: flex;
  align-items: center;
}

.info_header .extra_menu .container .navigation .logo {
  display: flex;
  align-items: center;
  margin-right: 16px;
}

.info_header .extra_menu .container .navigation .mob_logo {
  display: none;
}

.info_header .extra_menu .container .navigation ul {
  display: flex;
  width: 100%;
  margin-left: 20px;
  padding: 10px;
  background: linear-gradient(to bottom, #f39100, #f39100, #f39100, rgba(0, 0, 0, 0.3));
}

.info_header .extra_menu .container .navigation ul li {
  list-style: none;
  padding: 0 10px;
  border-left: 2px solid #c54a33;
}

.info_header .extra_menu .container .navigation ul li a {
  position: relative;
  text-decoration: none;
  color: #fff;
  font-weight: 700;
  font-size: 15px;
  text-transform: uppercase;
  transition: all ease-out 0.3s;
}

.info_header .extra_menu .container .navigation ul li a:hover {
  text-decoration: underline;
  color: #5a070f;
}

.info_header .extra_menu .container .navigation ul li:first-child {
  border: none;
}

.info_header .extra_menu .container .navigation ul .drop a {
  padding-right: 7px;
  padding-bottom: 9px;
  outline: none;
}

.info_header .extra_menu .container .navigation ul .drop a .fa {
  font-size: 20px;
  color: #fff;
}

.info_header .extra_menu .container .navigation ul .drop ul.dropdown {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  position: absolute;
  width: 170px;
  background: #f39100;
  visibility: hidden;
  opacity: 0;
  overflow: hidden;
  z-index: 1002;
  transform: translateY(35px);
  transition: translateY, 0.2s;
  border-top: 1px solid #A16000;
  padding: 0 20px 5px 10px;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 5px;
  margin-left: 0;
  padding-top: 5px;
}

.info_header .extra_menu .container .navigation ul .drop ul.dropdown li {
  padding: 0;
  padding: 3px 0 4px 0;
  border: none;
}

.info_header .extra_menu .container .navigation ul .drop ul.dropdown li a {
  color: #fff;
  font-size: 13px;
  line-height: 18px;
  text-transform: uppercase;
  font-weight: 700;
  transition: all ease-out 0.2s;
}

.info_header .extra_menu .container .navigation ul .drop ul.dropdown li a:hover {
  color: #5a070f;
}

.info_header .extra_menu .container .navigation ul .drop ul.dropdown li a:before {
  display: none;
}

.info_header .extra_menu .container .navigation ul li.drop:hover ul.dropdown {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
}

.info_header .extra_menu .container .navigation .entry {
  display: flex;
  align-items: center;
  display: none;
}

.info_header .extra_menu .container .navigation .entry a {
  text-transform: uppercase;
  text-decoration: none;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
}

.info_header .extra_menu .container .navigation .entry form {
  display: flex;
  align-items: center;
  position: relative;
}

.info_header .extra_menu .container .navigation .entry form .form_control {
  position: absolute;
  top: -3px;
  right: 85px;
  display: none;
  width: 550px;
}

.info_header .extra_menu .container .navigation .entry form .form_control .title_searchs {
  border: 1px solid #c10a2c;
  border-radius: 5px;
  height: 40px;
  width: 100%;
  box-shadow: 3px 3px 7px 0 rgba(200, 200, 200, 0.5);
  padding-left: 10px;
  box-sizing: border-box;
  background: #F2F2F2;
}

.info_header .extra_menu .container .navigation .entry form .form_control .button {
  position: absolute;
  content: "";
  top: 4px;
  right: 0;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  color: #fff;
  padding: 8px 15px 8px 0;
  border-radius: 30px;
  color: #333;
  z-index: 100;
}

.info_header .extra_menu .container .navigation .entry form .search {
  margin: 0 30px 0 20px;
  cursor: pointer;
  transition: all .4s ease;
}

.info_header .extra_menu .container .navigation .entry form .search:hover {
  filter: invert(100%);
}

.info_header .navbar-header {
  display: none;
  justify-content: space-between;
  align-items: center;
  z-index: 1002;
  padding: 5px 0 3px 0;
  background: #e08600;
}

.info_header .navbar-header .logo_mob {
  display: none;
}

.info_header .navbar-header .logo_mob a {
  text-decoration: none;
}

.info_header .navbar-header .logo_mob a img {
  width: 70px;
}

.info_header .navbar-header .menu-trigger,
.info_header .navbar-header .menu-trigger span {
  transition: all .4s;
  box-sizing: border-box;
  z-index: 1002;
}

.info_header .navbar-header .menu-trigger {
  position: relative;
  width: 37px;
  height: 23px;
}

.info_header .navbar-header .menu-trigger span {
  position: absolute;
  right: 0;
  width: 100%;
  height: 3px;
  background-color: #fff;
  border-radius: 4px;
}

.info_header .navbar-header .menu-trigger span:nth-of-type(1) {
  top: 0;
}

.info_header .navbar-header .menu-trigger span:nth-of-type(2) {
  top: 10px;
}

.info_header .navbar-header .menu-trigger span:nth-of-type(3) {
  bottom: 0;
}

.info_header #show.actives span:nth-of-type(1) {
  transform: translateY(10px) rotate(-315deg);
}

.info_header #show.actives span:nth-of-type(2) {
  opacity: 0;
}

.info_header #show.actives span:nth-of-type(3) {
  transform: translateY(-12px) rotate(315deg);
}

.info_header .burger-menu {
  position: fixed;
  width: 300px;
  height: 100%;
  top: 0;
  overflow-y: auto;
  overflow-x: hidden;
  opacity: 0;
  visibility: hidden;
  z-index: 9999;
  left: 0;
  background: #fff;
  transform: translate(-300px, 0);
  transition: transform 500ms linear;
}

.info_header .burger-menu .navi {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 999999;
}

.info_header .burger-menu .navi .block_caption {
  background: url(../img/manufacturing-and-delivery-of-26-district-heating-substations-with-scada-system-sidio-to-ptolemaida-greece_f.jpg) no-repeat;
  background-size: cover;
  height: 120px;
  position: relative;
}

.info_header .burger-menu .navi .block_caption:before {
  position: absolute;
  content: "";
  background: #55221f;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
}

.info_header .burger-menu .navi .block_caption .images {
  position: relative;
  padding: 15px 0 0 105px;
}

.info_header .burger-menu .navi .block_caption .images img {
  width: 80px;
  box-sizing: border-box;
}

.info_header .burger-menu .navi .nav {
  padding-top: 10px;
  outline: none;
}

.info_header .burger-menu .navi .nav li {
  list-style: none;
  padding: 7px 0;
  padding-left: 15px;
  transition: all ease-out 0.2s;
  overflow: hidden;
  position: relative;
  outline: none;
}

.info_header .burger-menu .navi .nav li a {
  outline: none;
  display: flex;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  color: #595959;
  font-family: 'PT Sans Narrow', sans-serif;
}

.info_header .burger-menu .navi .nav .drop > a:before {
  position: absolute;
  top: 7px;
  right: 15px;
  font-weight: normal;
  font-style: normal;
  font-family: FontAwesome;
  border: none;
  content: "\f107";
  color: #D10000;
  font-size: 19px;
}

.info_header .burger-menu .navi .nav li:nth-child(5) {
  border-bottom: 1px solid #E8E8E8;
  margin-bottom: 10px;
  padding-bottom: 15px;
}

.info_header .burger-menu .navi .nav .dropdown {
  display: none;
  padding-top: 8px;
}

.info_header .burger-menu .navi .nav .dropdown li {
  border: none;
  padding: 4px 0;
  padding-left: 10px;
}

.info_header .burger-menu .navi .nav .dropdown li a {
  padding-left: 5px;
  font-size: 14px;
}

.info_header .burger-menu .navi .nav .dropdown li a:before {
  display: none;
}

.info_header .burger-menu .navi .nav .active {
  background: #F0F0F0;
}

.info_header .burger-menu .navi .nav .active a:before {
  transform: rotate(180deg);
}

.info_header .burger-menu .navi .nav .active .dropdown {
  display: block;
}

.info_header .burger-menu .navi .information {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-top: 10px;
  margin-top: 10px;
  border-top: 1px solid #E8E8E8;
}

.info_header .burger-menu .navi .information a {
  margin: 0;
  padding: 0;
  text-decoration: none;
  font-size: 15px;
  font-weight: 500;
  color: #595959;
  font-family: 'Open Sans', sans-serif;
  margin: 7px 0;
}

.info_header .burger-menu.active {
  opacity: 1;
  visibility: visible;
}

.info_header .burger-menu.active {
  transform: translate(0, 0);
}

.info_poster .container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  background: #55221f url(../img/wrap_bg.png) repeat-y 7px 0;
  padding-bottom: 20px;
}

.info_poster .container .block {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(75% - 30px);
  padding-left: 20px;
}

.info_poster .container .block .content {
  display: flex;
  padding-top: 10px;
}

.info_poster .container .block .content .data {
  background: #fff;
  width: 23%;
  padding: 20px 10px 20px 15px;
}

.info_poster .container .block .content .data .time {
  display: flex;
  align-items: center;
}

.info_poster .container .block .content .data .time .number {
  font-size: 100px;
  color: #55221f;
  line-height: 90px;
}

.info_poster .container .block .content .data .time .dt {
  padding-left: 20px;
}

.info_poster .container .block .content .data .time .dt p {
  padding-bottom: 3px;
}

.info_poster .container .block .content .data .time .dt p:first-child {
  text-transform: uppercase;
}

.info_poster .container .block .content .data h2 {
  color: #f39100;
  font-weight: 700;
  font-size: 16px;
  padding-top: 10px;
}

.info_poster .container .block .content .text {
  background: #fee5ca;
  width: 77%;
  padding-bottom: 20px;
  padding-right: 15px;
}

.info_poster .container .block .content .text h2 {
  color: #f7931d;
  font-size: 23px;
  padding: 20px 5px 15px 20px;
  font-weight: bold;
  font-family: tahoma;
  line-height: 28px;
}

.info_poster .container .block .content .text .author {
  font-size: 22px;
  color: #5a070f;
  font-weight: 700;
  padding: 20px 5px 10px 20px;
  font-family: 'MinionPro-Bold';
}

.info_poster .container .block .content .text .short {
  padding-left: 21px;
}

.info_poster .container .block .content .text .short .headlin {
  color: #5a070f;
  font-weight: bold;
  font-size: 30px;
  line-height: 33px;
  font-family: 'MinionPro-Bold';
  padding-left: 0;
  text-decoration: none;
}

.info_poster .container .block .content .text .short .headlin a {
  color: #5a070f;
  font-weight: bold;
  font-size: 30px;
  line-height: 33px;
  font-family: 'MinionPro-Bold';
  padding-left: 0;
  text-decoration: none;
}

.info_poster .container .block .content .text .short .description {
  padding-top: 10px;
  color: #5a070f;
  font-size: 23px;
  line-height: 24px;
  font-weight: 700;
  font-family: 'MinionPro-Bold';
}

.info_poster .container .block .content .text .short .information {
  padding-top: 7px;
  color: #5a070f;
  font-size: 18px;
  line-height: 20px;
  font-weight: bold;
  font-family: 'MinionPro-Semibold';
}

.info_poster .container .block .content .text .short .ubs-buy-block .ubs-buy-button {
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  padding: 3px 20px;
  margin-top: 10px;
}

.info_poster .container .block .content:nth-child(2n+1) .data {
  background: #fff;
}

.info_poster .container .block .content:nth-child(2n) .data {
  background: #55221f;
}

.info_poster .container .block .content:nth-child(2n) .data .time .number {
  color: #fff;
}

.info_poster .container .block .content:nth-child(2n) .data .time .dt p {
  color: #fff;
}

.info_poster .container .news_post {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(75% - 30px);
  padding-left: 20px;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.info_poster .container .news_post .news {
  width: 31%;
  background: #fff;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
}

.info_poster .container .news_post .news .img {
  overflow: hidden;
  height: 250px;
}

.info_poster .container .news_post .news .img img {
  width: 100%;
}

.info_poster .container .news_post .news .text {
  padding: 10px 10px 10px 10px;
}

.info_poster .container .news_post .news .text .content h2 {
  font-size: 16px;
  font-weight: 700;
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-bottom: 1px solid #55221f;
  color: #55221f;
}

.info_poster .container .news_post .news .text .content p {
  font-size: 15px;
}

.info_poster .container .news_post .news .text .content a {
  color: #55221f;
  font-weight: 700;
}

.info_poster .container .news_post .news .data {
  padding: 10px 10px 10px 10px;
  display: flex;
  margin-top: auto;
}

.info_poster .container .news_post .news .data span {
  font-weight: 700;
  color: #55221f;
  padding-right: 10px;
}

.info_poster .container .news_post .news .data p {
  font-weight: 700;
}

.info_poster .container .additional_navigation {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(25% - 30px);
  padding-left: 5px;
  padding-top: 10px;
}

.info_poster .container .additional_navigation .block {
  background: #feead2;
  padding: 20px 5px 10px 20px;
  width: 95%;
}

.info_poster .container .additional_navigation .block h2 {
  color: #55221f;
  font-weight: 700;
  font-size: 18px;
  padding: 15px 10px 10px 20px;
  margin-bottom: 15px;
  border-bottom: 1px solid #55221f;
}

.info_poster .container .additional_navigation .block ul li {
  list-style: none;
  padding-bottom: 10px;
}

.info_poster .container .additional_navigation .block ul li a {
  text-decoration: none;
  color: #f29400;
  font-size: 16px;
  font-weight: bold;
}

.info_poster .container .additional_navigation .block ul li a:hover {
  text-decoration: underline;
}

.info_poster .container .musical_performances {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(25% - 30px);
  padding-left: 5px;
  padding-top: 10px;
}

.info_poster .container .musical_performances h2 {
  color: #f7931d;
  font-weight: 700;
  font-size: 18px;
  padding: 15px 10px 10px 20px;
  margin-bottom: 10px;
  border-bottom: 1px solid #f7931d;
}

.info_poster .container .musical_performances .block {
  background: #feead2;
  padding: 20px 5px 10px 20px;
  width: 95%;
}

.info_poster .container .musical_performances .block ul {
  padding-top: 15px;
}

.info_poster .container .musical_performances .block ul li {
  list-style: none;
  padding-bottom: 5px;
}

.info_poster .container .musical_performances .block ul li a {
  display: block;
  text-decoration: none;
  color: #55221f;
  margin-bottom: 5px;
  padding-bottom: 5px;
  border-bottom: 1px dotted #55221f;
  font-size: 15px;
  font-weight: bold;
}

.info_poster .container .musical_performances .block ul li a:hover {
  text-decoration: underline;
}

.info_poster .container .musical_performances .block .programs {
  display: flex;
  flex-direction: column;
}

.info_poster .container .musical_performances .block .programs a {
  color: #D18000;
  text-decoration: none;
  font-weight: 700;
  font-size: 16px;
  display: inline-block;
  margin-top: 5px;
  margin-bottom: 10px;
}

.info_poster .container .text_content {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(50% - 30px);
  margin-top: 10px;
}

.info_poster .container .text_content .text {
  background: #fff;
  padding: 0 15px 20px 15px;
}

.info_poster .container .text_content .text h2 {
  font-size: 25px;
  font-weight: 700;
  padding: 25px 0 20px 0;
  text-align: center;
  line-height: 24px;
}

.info_poster .container .text_content .text p {
  padding-bottom: 10px;
}

.info_poster .container .text_content .text p span {
  font-weight: 700;
}

.info_poster .container .performances_content {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(50% - 30px);
  background: #fff;
  margin-top: 10px;
}

.info_poster .container .performances_content h2 {
  font-size: 25px;
  font-weight: 700;
  padding: 25px 0 30px 0;
  text-align: center;
  line-height: 24px;
}

.info_poster .container .performances_content .content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.info_poster .container .performances_content .content a {
  width: 30%;
  text-decoration: none;
  margin: 0 10px 10px 10px;
}

.info_poster .container .performance_container {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(50% - 30px);
  background: #fff;
  margin-top: 10px;
  padding-bottom: 50px;
}

.info_poster .container .performance_container .caption h1 {
  font-size: 27px;
  font-weight: 700;
  padding: 25px 0 10px 0;
  text-align: center;
  line-height: 24px;
}

.info_poster .container .performance_container .caption h2 {
  font-size: 18px;
  font-weight: 700;
  padding: 5px 0 30px 0;
  text-align: center;
  line-height: 24px;
  color: #55221f;
}

.info_poster .container .performance_container .content {
  display: flex;
  flex-wrap: wrap;
  padding-right: 10px;
}

.info_poster .container .performance_container .content .photo {
  width: 35%;
  text-decoration: none;
  margin: 0 20px 10px 10px;
}

.info_poster .container .performance_container .content .photo img {
  width: 100%;
}

.info_poster .container .performance_container .content .text {
  width: 60%;
}

.info_poster .container .performance_container .content .text .filmmakers p {
  font-size: 16px;
  padding-bottom: 6px;
  line-height: 19px;
}

.info_poster .container .performance_container .content .text .cast h2 {
  font-size: 19px;
  font-weight: 700;
  padding: 20px 0 10px 0;
}

.info_poster .container .performance_container .content .text .cast p {
  font-size: 15px;
  padding-bottom: 6px;
  line-height: 19px;
}

.info_poster .container .coming_performances {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(25% - 30px);
  background: #feead2;
  margin-top: 10px;
  padding: 0 15px 0 15px;
}

.info_poster .container .coming_performances h2 {
  color: #f7931d;
  font-weight: 700;
  font-size: 18px;
  padding: 15px 10px 10px 20px;
  margin-bottom: 10px;
  border-bottom: 1px solid #f7931d;
}

.info_poster .container .coming_performances .poster a {
  text-decoration: none;
}

.info_poster .container .coming_performances .poster a .tally {
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  color: #55221f;
}

.info_poster .container .coming_performances .poster a .name {
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  color: #55221f;
  margin-bottom: 5px;
  padding-bottom: 5px;
  border-bottom: 1px dotted #55221f;
}

.info_poster .container .coming_performances .poster .af {
  color: #D18000;
  text-decoration: none;
  font-weight: 700;
  font-size: 16px;
  display: inline-block;
  margin-top: 5px;
}

.info_poster .container .coming_performances .contacts {
  padding-top: 10px;
}

.info_poster .container .coming_performances .contacts h3 {
  font-size: 14px;
  font-weight: 700;
  color: #55221f;
  padding-bottom: 7px;
  padding-top: 10px;
  font-family: 'FranklinGothicHeavyC';
}

.info_poster .container .coming_performances .contacts p {
  padding-bottom: 3px;
}

.info_poster .container .coming_performances .contacts p span {
  font-weight: 400;
  color: #55221f;
  padding-right: 5px;
  font-size: 13px;
  font-family: 'FranklinGothicHeavyC';
}

.info_footer .container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  background-color: #55221f;
  padding-bottom: 10px;
  position: relative;
}

.info_footer .container:before {
  content: "";
  position: absolute;
  background: url("../img/bottom_wrap.png") no-repeat left bottom;
  left: 0;
  top: -4px;
  z-index: 3;
  width: 50px;
  height: 100%;
}

.info_footer .container .inquiries {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(25% - 30px);
  text-align: center;
  z-index: 1000;
}

.info_footer .container .inquiries h2 {
  color: #f39100;
  font-size: 17px;
  font-weight: bold;
  padding-bottom: 5px;
  padding-top: 5px;
}

.info_footer .container .inquiries .img {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  background: #fff;
  padding: 7px 10px;
  border-radius: 5px;
  width: 70%;
}

.info_footer .container .booking_tickets {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(33.333333333333336% - 30px);
}

.info_footer .container .booking_tickets h2 {
  color: #fff;
  font-size: 17px;
  font-weight: bold;
  line-height: 24px;
}

.info_footer .container .booking_tickets p {
  color: #f39100;
  font-size: 17px;
  line-height: 20px;
}

.info_footer .container .svk {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(16.666666666666668% - 30px);
}

.info_footer .container .svk a {
  text-decoration: none;
  padding-right: 5px;
}

.info_footer .container .block {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(25% - 30px);
}

.info_footer .container .block p {
  color: #fff;
  font-size: 17px;
  line-height: 21px;
}

.info_footer_nav .container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  justify-content: center;
}

.info_footer_nav .container .block {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(66.66666666666667% - 30px);
  padding: 10px 0;
}

.info_footer_nav .container .block .nav {
  display: flex;
  justify-content: flex-end;
}

.info_footer_nav .container .block .nav li {
  list-style: none;
  padding: 5px 10px;
}

.info_footer_nav .container .block .nav li a {
  text-decoration: none;
  color: #fff;
  text-transform: uppercase;
}

.info_footer_nav .container .block p {
  display: flex;
  justify-content: flex-end;
  padding-right: 50px;
  padding-top: 10px;
  color: #fff;
}

.info_footer_nav .container .block_eye {
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;
  word-wrap: break-word;
  width: calc(33.333333333333336% - 30px);
  display: flex;
  justify-content: flex-end;
  padding: 10px 0;
}

.info_footer_nav .container .block_eye a {
  text-decoration: none;
  display: flex;
  align-items: center;
}

.info_footer_nav .container .block_eye a span {
  text-decoration: none;
  color: #fff;
  font-size: 19px;
  padding-left: 15px;
}

@media screen and (max-width: 1100px) {
  .wrapper {
    padding-left: 15px;
    padding-right: 15px;
  }

  .info_header {
    background: #e08600;
  }

  .info_header .containers .block {
    width: calc(66.66666666666667% - 30px);
  }

  .info_header .containers .block {
    justify-content: flex-end;
  }

  .info_header .containers .block .logo {
    display: none;
  }

  .info_header .extra_menu {
    background: #E08600;
  }

  .info_header .extra_menu .container .navigation .logo {
    display: none;
  }

  .info_header .extra_menu .container .navigation .mob_logo {
    display: block;
  }

  .info_header .extra_menu .container {
    display: none;
  }

  .info_header .navbar-header .logo_mob {
    display: block;
  }

  .info_header .navbar-header {
    display: flex;
  }

  .info_poster .container .block .content .data .time .number {
    font-size: 80px;
  }

  .info_poster .container .block .content .data h2 {
    font-size: 15px;
    padding-top: 5px;
  }

  .info_poster .container .block .content .text .premiere {
    font-size: 19px;
    line-height: 24px;
    padding-bottom: 10px;
  }

  .info_poster .container .block .content .text .short a {
    font-size: 25px;
    line-height: 24px;
  }

  .info_poster .container .block .content .text .short .description {
    font-size: 18px;
    line-height: 18px;
  }

  .info_poster .container .block .content .text .short .information {
    font-size: 18px;
    line-height: 18px;
  }

  .info_poster .container .additional_navigation {
    display: none;
  }

  .info_poster .container .musical_performances {
    display: none;
  }

  .info_poster .container .text_content {
    width: calc(75% - 30px);
  }

  .info_poster .container .text_content .text {
    margin-left: 30px;
  }

  .info_poster .container .performances_content {
    width: calc(75% - 30px);
  }

  .info_poster .container .performance_container {
    width: calc(75% - 30px);
  }

  .info_footer .container {
    padding-bottom: 23px;
  }

  .info_footer .container:before {
    top: -19px;
  }

  .info_footer .container .block p {
    font-size: 15px;
    line-height: 18px;
  }
}

@media screen and (max-width: 960px) {
  .wrapper {
    padding-left: 15px;
    padding-right: 15px;
  }

  .info_header .containers .block {
    width: calc(100% - 30px);
  }

  .info_poster .container .news_post .news {
    width: 47%;
  }

  .info_footer .container {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .info_footer .container:before {
    display: none;
  }

  .info_footer .container .inquiries h2 {
    font-size: 15px;
    line-height: 18px;
    padding-top: 0;
  }

  .info_footer .container .booking_tickets h2 {
    font-size: 15px;
    line-height: 18px;
  }

  .info_footer .container .booking_tickets p {
    font-size: 15px;
    line-height: 18px;
  }

  .info_footer .container .svk a img {
    width: 40px;
  }

  .info_footer .container .block p {
    font-size: 15px;
    line-height: 18px;
  }
}

@media screen and (max-width: 780px) {
  .wrapper {
    padding-left: 15px;
    padding-right: 15px;
  }

  .info_poster .container .block {
    width: calc(66.66666666666667% - 30px);
  }

  .info_poster .container .block .content {
    flex-wrap: wrap;
    padding-bottom: 10px;
  }

  .info_poster .container .block .content .data {
    width: 100%;
  }

  .info_poster .container .block .content .text {
    width: 100%;
  }

  .info_poster .container .block .content:nth-child(2n) .data {
    background: #fff;
  }

  .info_poster .container .block .content:nth-child(2n) .data .time .number {
    color: #55221f;
  }

  .info_poster .container .block .content:nth-child(2n) .data .time .dt p {
    color: #55221f;
  }

  .info_poster .container .news_post {
    width: calc(66.66666666666667% - 30px);
  }

  .info_poster .container .news_post .news {
    width: 100%;
  }

  .info_poster .container .text_content {
    width: calc(66.66666666666667% - 30px);
  }

  .info_poster .container .text_content .text {
    margin-left: 20px;
    margin-bottom: 10px;
  }

  .info_poster .container .performances_content {
    width: calc(66.66666666666667% - 30px);
  }

  .info_poster .container .performances_content .content a {
    width: 100%;
  }

  .info_poster .container .performance_container {
    width: calc(66.66666666666667% - 30px);
  }

  .info_poster .container .coming_performances {
    width: calc(33.333333333333336% - 30px);
  }

  .info_footer .container .inquiries {
    padding-bottom: 10px;
  }

  .info_footer .container .booking_tickets {
    padding-bottom: 10px;
  }

  .info_footer .container .svk {
    padding-bottom: 10px;
    text-align: center;
  }

  .info_footer .container .block {
    padding-bottom: 10px;
  }

  .info_footer .container .booking_tickets {
    text-align: center;
  }

  .info_footer .container .block {
    text-align: center;
  }

  .info_footer .container .inquiries {
    width: calc(50% - 30px);
  }

  .info_footer .container .booking_tickets {
    width: calc(50% - 30px);
  }

  .info_footer .container .svk {
    width: calc(50% - 30px);
  }

  .info_footer .container .block {
    width: calc(50% - 30px);
  }

  .info_footer_nav .container .block .nav {
    justify-content: center;
  }

  .info_footer_nav .container .block p {
    justify-content: center;
    padding-right: 0;
  }

  .info_footer_nav .container .block_eye {
    justify-content: center;
  }

  .info_footer_nav .container .block {
    width: calc(100% - 30px);
  }

  .info_footer_nav .container .block_eye {
    width: calc(100% - 30px);
  }
}

@media screen and (max-width: 560px) {
  .wrapper {
    padding-left: 15px;
    padding-right: 15px;
  }

  .info_header .containers {
    background: #55221f;
    height: 210px;
  }

  .info_header .containers .block {
    justify-content: flex-start;
  }

  .info_header .containers .block .info p {
    padding-bottom: 0;
    text-align: inherit;
    line-height: 18px;
  }

  .info_header .containers .block .info h2 {
    text-align: inherit;
  }

  .info_header .containers .block .info h3 {
    text-align: inherit;
  }

  .info_poster .container .block {
    width: calc(100% - 30px);
  }

  .info_poster .container .block .content .data .time .number {
    font-size: 70px;
    line-height: 30px;
  }

  .info_poster .container .block .content .data .time .dt p {
    line-height: 18px;
  }

  .info_poster .container .block .content .data h2 {
    font-size: 15px;
    padding-top: 5px;
  }

  .info_poster .container .block .content .text {
    padding-right: 5px;
  }

  .info_poster .container .block .content .text .premiere {
    font-size: 16px;
    line-height: 20px;
    padding-bottom: 10px;
  }

  .info_poster .container .block .content .text .short a {
    font-size: 20px;
    line-height: 20px;
  }

  .info_poster .container .block .content .text .short .description {
    font-size: 16px;
    line-height: 18px;
  }

  .info_poster .container .block .content .text .short .information {
    font-size: 16px;
    line-height: 18px;
  }

  .info_poster .container .news_post {
    width: calc(100% - 30px);
  }

  .info_poster .container .text_content {
    width: calc(100% - 30px);
  }

  .info_poster .container .performances_content {
    width: calc(100% - 30px);
  }

  .info_poster .container .performance_container {
    width: calc(100% - 30px);
  }

  .info_poster .container .coming_performances {
    width: calc(100% - 30px);
  }

  .info_poster .container .coming_performances {
    margin-left: 35px;
    padding-bottom: 20px;
  }

  .info_poster .container .coming_performances h2 {
    padding-left: 0;
  }

  .info_footer .container .inquiries {
    width: calc(100% - 30px);
  }

  .info_footer .container .booking_tickets {
    width: calc(100% - 30px);
  }

  .info_footer .container .svk {
    width: calc(100% - 30px);
  }

  .info_footer .container .block {
    width: calc(100% - 30px);
  }

  .info_footer_nav .container .block .nav {
    flex-direction: column;
  }

  .info_footer_nav .container .block .nav li {
    padding: 2px 0;
  }

  .info_footer_nav .container .block_eye a img {
    width: 50px;
  }
}

@media screen and (max-width: 400px) {
  .wrapper {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media screen and (max-width: 360px) {
  .wrapper {
    padding-left: 15px;
    padding-right: 15px;
  }
}